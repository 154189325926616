body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

hr {
  height:2px;
  border-width:0;
  color:gray;
  background-color:gray
  
}

.wrapped {
  word-wrap: break-word;
}

.inputCustom { 
    text-align: right; 
}

.chart-cont{
  background-color:lightgray
}

.chart-card{
  background-color:#5e5e5e;
}

.hold-user-subandcomment{
  background-color:#8fbaff;
}

.hold-user-inputtab{
  background-color:#9b61ff;
}

.dumbstuff{
  display:'none'
}

.inlineHelper{
  display: 'inline-block'
}

.hovertext { cursor: pointer; }

.hoverDiv:hover {
  background: gray;
  animation-name: floating; 
  animation-duration: 3s; 
  animation-iteration-count: infinite; 
  animation-timing-function: ease-in-out; 
}

.inputFieldRounding { border-radius: 200px}

@keyframes floating { 
  0% { transform: translate(0,  0px); } 
  50%  { transform: translate(0, -5px); } 
  100%   { transform: translate(0, -0px); }     
} 

.top5 { margin-top:5px; }
.top7 { margin-top:7px; }
.top10 { margin-top:10px; }
.top15 { margin-top:15px; }
.top17 { margin-top:17px; }
.top30 { margin-top:30px; }

.bottom5 { margin-bottom:5px; }
.bottom7 { margin-bottom:7px; }
.bottom10 { margin-bottom:10px; }
.bottom15 { margin-bottom:15px; }
.bottom17 { margin-bottom:17px; }
.bottom30 { margin-bottom:30px; }

.specialbutton{outline-color:#3e629c!important}